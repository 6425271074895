<template>
    <div class="d-flex align-items-center flex-column" style="height: 105%">
        <div class="w-100 d-flex h-100">
            <div class="card card-custom gutter-b p-5 align-items-center w-75 mr-5">
                <p>URL : {{visio.url}}</p>
                <iframe v-if="visio.url" :src="visio.url" frameborder="0" style="width: 100%; height: 100%;"></iframe>
            </div>
            <div class="card card-custom gutter-b p-5 align-items-center w-25">
                <p>Le chat</p>
            </div>
        </div>
    </div>
</template>
<script>

import {GET_VISIO} from "../../core/services/store/api/videoconference.service";

export default {
    name: "Visios",
    data() {
        return {
            loading: true,
            tuteur: false,
            visio: {},
        }
    },
    async mounted() {
        if (!this.$route.params.id) {
            this.$router.push({name: 'dashboard'});
        } else {
            await this.checkTuteur([1]);
            await this.getVisio();
        }
    },
    methods: {
        getVisio() {
            this.$store.dispatch(GET_VISIO, this.$route.params.id)
                .then(response => {
                    //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                    if (this.tuteur) {
                        if (response.data.tuteur_id !== this.$store.getters.currentUser.id) {
                            this.$router.push({name: 'dashboard'});
                        } else {
                            this.loading = false;
                        }
                    } else {
                        this.loading = false;
                    }
                    this.visio = response.data;
                })
                .catch(() => {
                    this.$router.push({name: 'dashboard'});
                });
        },
        checkTuteur(rightIds) {
            if ((this.$store.getters.currentUser && this.$store.getters.currentUser.rights && this.$store.getters.currentUser.rights.academy)) {
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        },
    }
}
</script>